import { GameLibrary } from "@components/sections/GameLibrary"
import { PageFooter } from "@components/sections/PageFooter"
import { DynamicPageHeader } from "@components/sections/PageHeader"
import { PageBanner } from "@components/sections/PageBanner"
import PHOTO_2 from "@static/won/posters/won_poster_2_v006_plain.jpg";


export function TowerOfPuzzlesPrivacyPolicy() {



    return (
        <div className="min-h-screen bg-slate-900">
            <DynamicPageHeader />
            <div className="">
                <div className="relative h-80 md:h-100 flex items-center justify-center flex-grow">
                    <div className="absolute z-0">
                        <PageBanner src={PHOTO_2} />
                    </div>
                    <div className="absolute z-10 w-full">
                        <div className="bg-black h-16 opacity-0"></div>
                        <div className="bg-black h-36 opacity-25"></div>
                    </div>
                    <div className="absolute z-20">
                        <div className="text-white text-3xl md:text-5xl font-bold text-center text-opacity-0">W</div>
                        <div className="text-white text-3xl md:text-5xl font-bold text-center">Tower of Puzzles</div>
                        <div className="text-white text-5xl md:text-7xl font-bold text-center">Privacy Policy</div>
                    </div>
                </div>
                <div className="py-4 px-4 md:px-48">
                    <div className="text-white text-xl font-bold mb-2">Privacy Policy</div>
                    <p className="text-white text-justify text-md mb-4">This privacy policy applies to the Tower of Puzzles app (hereby referred to as "Application") for mobile devices that was created by Dreamers Graphic Studio (hereby referred to as "Service Provider") as a Free service. This service is intended for use "AS IS".</p>
                    <div className="text-white text-xl font-bold mb-2">What information does the Application obtain and how is it used?</div>
                    <p className="text-white text-md text-justify mb-4">The Application does not obtain any information when you download and use it. Registration is not required to use the Application.</p>
                    <div className="text-white text-xl font-bold mb-2">Does the Application collect precise real time location information of the device?</div>
                    <p className="text-white text-md text-justify mb-4">This Application does not collect precise information about the location of your mobile device.</p>
                    <div className="text-white text-xl font-bold mb-2">Do third parties see and/or have access to information obtained by the Application?</div>
                    <p className="text-white text-md text-justify mb-4">Since the Application does not collect any information, no data is shared with third parties.</p>
                    <div className="text-white text-xl font-bold mb-2">What are my opt-out rights?</div>
                    <p className="text-white text-md text-justify mb-4">You can stop all collection of information by the Application easily by uninstalling it. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.</p>
                    <div className="text-white text-xl font-bold mb-2">Children</div>
                    <p className="text-white text-md text-justify mb-2">The Application is not used to knowingly solicit data from or market to children under the age of 13.</p>
                    <p className="text-white text-md text-justify mb-4">The Service Provider does not knowingly collect personally identifiable information from children. The Service Provider encourages all children to never submit any personally identifiable information through the Application and/or Services. The Service Provider encourage parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their children never to provide personally identifiable information through the Application and/or Services without their permission. If you have reason to believe that a child has provided personally identifiable information to the Service Provider through the Application and/or Services, please contact the Service Provider
                        <a className="text-blue-400" href="mailto:info@dgstudio.games"> (info@dgstudio.games)</a> so that they will be able to take the necessary actions. You must also be at least 16 years of age to consent to the processing of your personally identifiable information in your country (in some countries we may allow your parent or guardian to do so on your behalf).</p>
                    <div className="text-white text-xl font-bold mb-2">Security</div>
                    <p className="text-white text-md text-justify mb-4">The Service Provider is concerned about safeguarding the confidentiality of your information. However, since the Application does not collect any information, there is no risk of your data being accessed by unauthorized individuals.</p>
                    <div className="text-white text-xl font-bold mb-2">Changes</div>
                    <p className="text-white text-md text-justify mb-2">This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes to their Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</p>
                    <p className="text-white text-md text-justify mb-4">This privacy policy is effective as of 2024-04-04</p>
                    <div className="text-white text-xl font-bold mb-2">Your Consent</div>
                    <p className="text-white text-md text-justify mb-4">By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by the Service Provider.</p>
                    <div className="text-white text-xl font-bold mb-2">Contact Us</div>
                    <p className="text-white text-md text-justify mb-2">If you have any questions regarding privacy while using the Application, or have questions about the practices, please contact the Service Provider via email at
                        <a className="text-blue-400" href="mailto:info@dgstudio.games"> info@dgstudio.games</a>.</p>
                    <p className="text-white text-md text-justify mb-4">This privacy policy page was generated by
                        <a className="text-blue-400" href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer"> App Privacy Policy Generator</a></p>
                </div>
                <PageFooter />
            </div>
        </div>
    )
}
