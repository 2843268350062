import { GetTagLink } from "@components/links/TagLink";
import { GetStoreLink } from "@components/links/StoreLink";
import { GamePlayGalleryDynamic } from "@components/containers/GamePlayGallery";
import { GameInformation, GameStores, ImplementedPlatform } from "@interfaces/games";
import { getGamePublishingStatus } from "@utils/game";


export interface GamePublishingStatusProps {
    developer: string
    publisher: string
    status: string
    date: string
}

export function GamePublishingStatus({ developer, publisher, status, date }: GamePublishingStatusProps) {
    return (
        <div className="flex flex-col gap-1">
            <div className="grid grid-flow-col grid-cols-3">
                <div className="col-span-1">
                    <div className="text-md text-white">Developer :</div>
                </div>
                <div className="col-span-2">
                    <div className="text-md font-semibold text-dgs-yellow">{developer}</div>
                </div>
            </div>
            <div className="grid grid-flow-col grid-cols-3">
                <div className="col-span-1">
                    <div className="text-md text-white">Publisher :</div>
                </div>
                <div className="col-span-2">
                    <div className="text-md font-semibold text-dgs-yellow">{publisher}</div>
                </div>
            </div>
            <div className="grid grid-flow-col grid-cols-3">
                <div className="col-span-1">
                    <div className="text-md text-white">Status :</div>
                </div>
                <div className="col-span-2">
                    <div className="text-md font-semibold text-dgs-yellow">{status}</div>
                </div>
            </div>
            <div className="grid grid-flow-col grid-cols-3">
                <div className="col-span-1">
                    <div className="text-md text-white">Release Date :</div>
                </div>
                <div className="col-span-2">
                    <div className="text-md font-semibold text-dgs-yellow">{date}</div>
                </div>
            </div>
        </div>
    )
}


export interface HorizontalPublishContainerProps {
    info: GameInformation
}

export function HorizontalPublishContainer({ info }: HorizontalPublishContainerProps) {
    return (
        <div className="min-w-fit max-w-5xl items-center justify-center">
            {/* Game Header */}
            <div className="flex flex-row py-4">
                {/* Game Title */}
                <div className="min-w-fit">
                    <div className=" text-4xl font-bold text-left text-nowrap text-white">{info.title}</div>
                </div>
                {/* Game Stores */}
                <div className="flex flex-row flex-grow items-center justify-end gap-2">
                    {info.stores.map((store: GameStores, index: number) => (
                        <GetStoreLink key={index} store={store} />
                    ))}
                </div>
            </div>
            {/* Game Info */}
            <div className="flex flex-row gap-4">
                {/* Game play library */}
                <GamePlayGalleryDynamic></GamePlayGalleryDynamic>
                {/* Game description */}
                <div className="w-52 xl:w-80 flex flex-col gap-4 flex-none">
                    {/* Game cover */}
                    <div className="h-32 xl:h-40 rounded-sm overflow-hidden flex items-center justify-center">
                        <img alt="..." src={info.cover} className='w-full object-cover'></img>
                    </div>
                    {/* Game description */}
                    <div className="text-justify text-sm font text-white">{info.description}</div>
                    {/* Game status */}
                    <GamePublishingStatus
                        developer={info.developer}
                        publisher={info.publisher}
                        status={getGamePublishingStatus(info.status)}
                        date={info.date}></GamePublishingStatus>

                    {/* Game platforms */}
                    <div className="flex flex-wrap gap-2">
                        {info.platforms.map((implementation: ImplementedPlatform, index: number) => (
                            <GetTagLink key={index} implementation={implementation} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export interface VerticalPublishContainerProps {
    info: GameInformation
}

export function VerticalPublishContainer({ info }: VerticalPublishContainerProps) {
    return (
        <div className="w-full items-center justify-center">
            {/* Game Header */}
            <div className="flex flex-col py-2">
                {/* Game Title */}
                <div className="min-w-fit">
                    <div className="text-2xl font-semibold pb-2 px-1
                    lg:text-4xl lg:font-bold lg:p-0
                    text-left text-nowrap text-white">{info.title}</div>
                </div>
                {/* Game Stores */}
                <div className="px-1 flex flex-row flex-wrap flex-grow items-center justify-start gap-2">
                    {info.stores.map((store: GameStores, index: number) => (
                        <GetStoreLink key={index} store={store} />
                    ))}
                </div>
            </div>
            {/* Game Info */}
            <div className="flex flex-col gap-4 p-1">
                {/* Game description */}
                <div className="w-full flex flex-col gap-4 flex-none">
                    {/* Game cover */}
                    {/* <div className="h-40 rounded-sm overflow-hidden flex items-center justify-center">
                        <img alt="..." src={info.cover} className='w-full object-cover'></img>
                    </div> */}
                    {/* Game status */}
                    <GamePublishingStatus
                        developer={info.developer}
                        publisher={info.publisher}
                        status={getGamePublishingStatus(info.status)}
                        date={info.date}></GamePublishingStatus>

                    {/* Game description */}
                    <div className="text-justify text-sm font text-white">{info.description}</div>

                    {/* Game platforms */}
                    <div className="flex flex-wrap gap-2">
                        {info.platforms.map((implementation: ImplementedPlatform, index: number) => (
                            <GetTagLink key={index} implementation={implementation} />
                        ))}
                    </div>
                </div>
                {/* Game play library */}
                <GamePlayGalleryDynamic></GamePlayGalleryDynamic>
            </div>
        </div>
    )
}
