import { Fragment, useState, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";


export interface GameContainerProps {
    loaderUrl: string,
    dataUrl: string,
    frameworkUrl: string,
    codeUrl: string,
}

export function GameContainer({ loaderUrl, dataUrl, frameworkUrl, codeUrl }: GameContainerProps) {
    const { unityProvider, isLoaded, loadingProgression, requestFullscreen, unload } = useUnityContext({
        loaderUrl: loaderUrl,
        dataUrl: dataUrl,
        frameworkUrl: frameworkUrl,
        codeUrl: codeUrl,
    });

    // We'll round the loading progression to a whole number to represent the
    // percentage of the Unity Application that has loaded.
    const loadingPercentage = Math.round(loadingProgression * 100);

    // We'll use a state to store the device pixel ratio.
    const [devicePixelRatio, setDevicePixelRatio] = useState(
        window.devicePixelRatio
    );

    useEffect(
        function () {
            // A function which will update the device pixel ratio of the Unity
            // Application to match the device pixel ratio of the browser.
            const updateDevicePixelRatio = function () {
                setDevicePixelRatio(window.devicePixelRatio);
            };
            // A media matcher which watches for changes in the device pixel ratio.
            const mediaMatcher = window.matchMedia(
                `screen and (resolution: ${devicePixelRatio}dppx)`
            );
            // Adding an event listener to the media matcher which will update the
            // device pixel ratio of the Unity Application when the device pixel
            // ratio changes.
            mediaMatcher.addEventListener("change", updateDevicePixelRatio);
            return function () {
                // Removing the event listener when the component unmounts.
                mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
            };
        },
        [devicePixelRatio]
    );

    function handleClickEnterFullscreen() {
        requestFullscreen(true);
    }

    async function handleClickBack() {
        await unload();
    }


    return (
        <div className="container relative flex flex-col mt-8 md:mt-0 gap-2 justify-center items-center">
            {isLoaded === false && (
                // We'll conditionally render the loading overlay if the Unity
                // Application is not loaded.
                <div className="absolute h-full w-full loading-overlay flex items-center justify-center">
                    <div className="relative flex items-center justify-center">
                        <div className="absolute z-30 h-8 lg:h-12 w-60 lg:w-80 flex items-center justify-center">
                            <div className=" text-sm lg:text-lg font-semibold text-center whitespace-nowrap text-dgs-yellow">
                                LOADING... {loadingPercentage}%</div>
                        </div>
                        <div className="absolute z-10 h-8 lg:h-12 w-60 lg:w-80 rounded-full overflow-clip">
                            <div className="h-full bg-gradient-to-t bg-dgs-blue-dark-1"
                                style={{ width: `${loadingPercentage}%` }}></div>
                        </div>
                        <div className="absolute z-20 h-8 lg:h-12 w-60 lg:w-80 rounded-full bg-gradient-to-t from-black"></div>
                        <div className="absolute z-0 h-8 lg:h-12 w-60 lg:w-80 rounded-full bg-dgs-night"></div>
                    </div>
                </div>
            )}
            <Fragment>
                <Unity className="unity"
                    unityProvider={unityProvider}
                    style={{ width: "100%", height: "100%" }}
                    devicePixelRatio={devicePixelRatio} />
                <div className="flex flex-row gap-10 p-4">
                    <div className="">
                        <button className="px-4 py-1 md:px-8 md:py-2 rounded-full bg-dgs-blue-dark-2 border-2 border-dgs-yellow"
                            onClick={handleClickBack}>
                            <a className="text-center font-semibold text-sm hover:text-md md:text-lg md:hover:text-2xl text-white" href="/games/weather-or-not">Back</a>
                        </button>
                    </div>
                    <div>
                        <button className="px-4 py-1 md:px-8 md:py-2 rounded-full bg-dgs-blue-dark-2 border-2 border-dgs-yellow"
                            onClick={handleClickEnterFullscreen}>
                            <div className="text-center font-semibold text-sm hover:text-md md:text-lg md:hover:text-2xl text-white">Fullscreen</div></button>
                    </div>
                </div>
            </Fragment>
        </div>
    );
}