import { createSlice } from "@reduxjs/toolkit"
import { GamePages } from "@interfaces/games"
import { WEATHER_OR_NOT_INFO, DEMOLITION_FORCE_INFO } from "@services/api/tempApi"


const initialState: GamePages = {
    "weather-or-not": WEATHER_OR_NOT_INFO,
    "demolition-force": DEMOLITION_FORCE_INFO,
}

export const gamePageSlice = createSlice({
    name: "gamePage",
    initialState,
    reducers: {},
})
