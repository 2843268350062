'use client';
import { useState } from 'react';
import { Dropdown } from 'flowbite-react';
import type { CustomFlowbiteTheme } from 'flowbite-react';
import { BsFacebook, BsInstagram, BsMailbox, BsTwitterX, BsYoutube } from 'react-icons/bs';
import { HiUserGroup, HiNewspaper, HiViewGrid } from 'react-icons/hi';
import { TextLink } from "@components/links/TextLink"
import { Children, ReactElement } from 'react';

const customTheme: CustomFlowbiteTheme['dropdown'] = {
    arrowIcon: "h-4 w-4",
    content: "py-1 focus:outline-none bg-dgs-night",
    floating: {
        animation: "transition-opacity",
        arrow: {
            base: "absolute z-10 h-2 w-2 rotate-45",
            style: {
                dark: "bg-dgs-night dark:bg-dgs-night",
                light: "bg-dgs-night",
                auto: "bg-dgs-night dark:bg-dgs-night"
            },
            placement: "-4px"
        },
        base: "z-10 w-fit rounded divide-y divide-gray-100 shadow focus:outline-none",
        content: "py-1 text-sm text-gray-700 dark:text-gray-200",
        divider: "my-1 h-px bg-gray-100 dark:bg-gray-600",
        header: "block py-2 px-4 text-sm font-semibold text-white dark:text-white",
        hidden: "invisible opacity-0",
        item: {
            container: "",
            base: "flex items-center justify-start py-2 px-4 text-sm font-semibold text-dgs-cyan hover:text-white cursor-pointer w-full dark:text-cyan focus:outline-none dark:hover:text-white dark:focus:text-white",
            icon: "mr-2 h-4 w-4"
        },
        style: {
            dark: "bg-dgs-night text-white dark:bg-dgs-night",
            light: "border border-gray-200 bg-dgs-night text-gray-900",
            auto: "border border-gray-200 bg-dgs-night text-gray-900 dark:border-none dark:bg-dgs-night dark:text-white"
        },
        target: "w-fit"
    },
    inlineWrapper: "flex items-center bg-dgs-night"
};

export function DropdownLeft() {
    return (
        <Dropdown theme={customTheme} label="" className='bg-dgs-night'>
            <Dropdown.Header>
                <span className="block text-sm">Menu</span>
            </Dropdown.Header>
            <Dropdown.Item icon={HiViewGrid} href='/#/#page-games'>Games</Dropdown.Item>
            <Dropdown.Item icon={HiNewspaper} href='/#/#page-news'>News</Dropdown.Item>
            <Dropdown.Item icon={HiUserGroup} href='/#/#team-list'>Team</Dropdown.Item>
        </Dropdown>
    );
}

export function DropdownRight() {
    return (
        <Dropdown theme={customTheme} label="" className='bg-dgs-night'>
            <Dropdown.Header>
                <span className="block text-sm">Follow Us</span>
            </Dropdown.Header>
            <Dropdown.Item icon={BsFacebook} href='https://www.facebook.com/DreamersGraphicStudio'>Facebook</Dropdown.Item>
            <Dropdown.Item icon={BsInstagram} href='https://www.instagram.com/d.g.studio/?hl=en'>Instagram</Dropdown.Item>
            <Dropdown.Item icon={BsYoutube} href='https://www.youtube.com/@dreamersgraphicstudio/featured'>YouTube</Dropdown.Item>
            <Dropdown.Item icon={BsTwitterX} href='https://twitter.com/dgstudiogames'>Twitter X</Dropdown.Item>
            <Dropdown.Item icon={BsMailbox} href='mailto:info@dgstudio.games'>Email</Dropdown.Item>
        </Dropdown>
    );
}

export interface DropdownMenuProps {
    url: string,
    text: string,
    children: ReactElement[],
}

export function DropdownMenu({ url, text, children }: DropdownMenuProps) {

    const [hidden, setHidden] = useState("hidden")
    const [highLight, setHighLight] = useState("")

    const onMouseEnter = () => {
        setHighLight("bg-dgs-night")
        setHidden("block")
    }
    const onMouseLeave = () => {
        setHidden("hidden")
        setHighLight("")
    }

    return (
        <div className='relative h-20 flex flex-col items-center'
            onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className='h-20 flex items-center justify-center'>
                <div className={`p-2 rounded-md hover:bg-dgs-night ${highLight}`}>
                    <TextLink url={url} text={text} />
                </div>
            </div>
            <div className={`${hidden} absolute w-80 rounded-md my-20 p-4 z-10
                bg-dgs-night`}>
                {children}
            </div>
        </div>
    )
}
