import { GamePlatform, ImplementedPlatform } from "@interfaces/games";
import { ReactElement } from "react"
import { FaApple, FaWindows } from "react-icons/fa";
import { GoBrowser } from "react-icons/go";
import { IoLogoAndroid } from "react-icons/io5";


export interface TagLinkProps {
    icon?: ReactElement
    title: string
    link: string
}

export function TagLink({ icon, title, link }: TagLinkProps) {
    return (
        <a href={link} className="px-4 py-2 bg-dgs-blue-dark-2 rounded-full flex flex-row items-center justify-end gap-2 
        hover:bg-dgs-yellow">
            {icon}
            <div className="font-semibold text-sm text-center text-dgs-cyan">
                {title}
            </div>
        </a>
    )
}

export interface PlatformTagLinkProps {
    title: string
    link: string
}

export function BrowserTagLink({ title, link }: PlatformTagLinkProps) {
    return (
        <TagLink icon={<GoBrowser className="" color="#00ffff"></GoBrowser>}
            title={title} link={link} />
    )
}

export function AndroidTagLink({ title, link }: PlatformTagLinkProps) {
    return (
        <TagLink icon={<IoLogoAndroid className="" color="#00ffff"></IoLogoAndroid>}
            title={title} link={link} />
    )
}

export function IosTagLink({ title, link }: PlatformTagLinkProps) {
    return (
        <TagLink icon={<FaApple className="" color="#00ffff"></FaApple>}
            title={title} link={link} />
    )
}

export function WindowsTagLink({ title, link }: PlatformTagLinkProps) {
    return (
        <TagLink icon={<FaWindows className="" color="#00ffff"></FaWindows>}
            title={title} link={link} />
    )
}

export interface GetTagLinkProps {
    implementation: ImplementedPlatform
}

export function GetTagLink({ implementation }: GetTagLinkProps) {
    switch (implementation.platform) {
        case GamePlatform.BROWSER:
            return <BrowserTagLink title="Browser" link={implementation.link} />
        case GamePlatform.IOS:
            return <IosTagLink title="iOS" link={implementation.link} />
        case GamePlatform.ANDROID:
            return <AndroidTagLink title="Android" link={implementation.link} />
        case GamePlatform.WINDOWS:
            return <WindowsTagLink title="Windows" link={implementation.link} />
        default:
            return <BrowserTagLink title="Browser" link={implementation.link} />
    }
}
