import { Library } from "@components/containers/Library";
import { List } from "@components/containers/List";
import { HorizontalProfile } from "@components/profiles/HorizontalProfile";
import { VerticalProfile } from "@components/profiles/VerticalProfile";
import HELIO from "@static/team/helio_da_silva_profile.jpg";
import FRANCESCO from "@static/team/francesco_scopa_profile_2.jpg.jpeg";
import DOGU from "@static/team/dogu_zaifoglu_profile.jpg";


export function TeamList() {
    return (
        <section id="team-list" className="w-full bg-slate-900">
            <div className="block md:hidden">
                <List title="OUR TEAM">
                    <HorizontalProfile src={HELIO} title="Hélio Da Silva" description="Lead Programmer, Game Designer, Illustrator" />
                    <HorizontalProfile src={FRANCESCO} title="Francesco Scopa" description="Game Designer, Programmer, Animator" />
                    <HorizontalProfile src={DOGU} title="Doğu Zaifoğlu" description="Soundtrack Composer, Foley Artist" />
                </List>
            </div>
            <div className="hidden md:block">
                <Library title="OUR TEAM" categories={[]}>
                    <VerticalProfile src={HELIO} title="Hélio Da Silva" description="Lead Programmer, Game Designer, Illustrator" />
                    <VerticalProfile src={FRANCESCO} title="Francesco Scopa" description="Game Designer, Programmer, Animator" />
                    <VerticalProfile src={DOGU} title="Doğu Zaifoğlu" description="Soundtrack Composer, Foley Artist" />
                </Library>
            </div>
        </section >
    )
}
