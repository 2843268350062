import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom"
import { PageFooter } from "@components/sections/PageFooter"
import { DynamicPageHeader } from "@components/sections/PageHeader"
import { PageBanner } from "@components/sections/PageBanner"
import { TeamList } from "@components/sections/TeamList"
import { HorizontalPublishContainer, VerticalPublishContainer } from "@components/containers/PublishContainer"
import { GameInformation } from "@interfaces/games"
import { useGetGameInfo } from "@services/hooks/gamePage"
import { setPublishingGalleryItems } from "@services/slice/publishingGallery";


export interface GenericGamePageProps {
    info: GameInformation
}

export function GenericGamePage({ info }: GenericGamePageProps) {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div className="min-h-screen bg-gradient-to-br from-slate-800 to-black">
            <DynamicPageHeader />
            <div className="">
                <div className="relative h-64 md:h-100 flex items-center justify-center overflow-hidden">
                    <PageBanner src={info.banner} />
                </div>
                <div className="px-2 py-6 lg:p-8 hidden md:flex items-center justify-center flex-col">
                    {/* Game Publishing Container */}
                    <HorizontalPublishContainer info={info}></HorizontalPublishContainer>
                </div>
                <div className="flex md:hidden items-center justify-center flex-col">
                    {/* Game Publishing Container */}
                    <VerticalPublishContainer info={info}></VerticalPublishContainer>
                </div>
            </div>
            <TeamList />
            <PageFooter />
        </div >
    )
}

export function DynamicGenericGamePage() {
    const { id } = useParams()
    const gameInfo = useGetGameInfo(id)
    console.log(id)

    const dispatch = useDispatch()

    if (gameInfo) {
        dispatch(setPublishingGalleryItems(gameInfo.gallery))
    }

    return (
        <>
            {gameInfo ? <GenericGamePage info={gameInfo} /> : <div>{`Game with id: ${id} Not Found`}</div>}
        </>
    )
}
