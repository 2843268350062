import { Outlet } from "react-router-dom";


export function LayoutPage() {

    return (
        <>
            <Outlet />
        </>
    )
}