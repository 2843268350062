import { GameDistributer, GameGalleryItemType, GameInformation, GamePlatform, GameStatus, PublishingGallery } from "@interfaces/games";
import WON_ICON from "@static/won/posters/won_poster_2_v006_plain.jpg";
import WON_COVER from "@static/won/posters/won_poster_2_v006_plain.jpg";
import WON_BANNER from "@static/won/posters/won_poster_1_2024_03_29_001_plain.png";
import WON_SCREENSHOT_1 from "@static/won/screenshots/won_screenshot_2024_08_10.png";
import WON_SCREENSHOT_2 from "@static/won/screenshots/won_screenshot_2024_08_10_2.png";
import WON_SCREENSHOT_3 from "@static/won/screenshots/won_screenshot_2024_08_10_3.png";
import WON_SCREENSHOT_4 from "@static/won/screenshots/won_screenshot_2024_08_10_4.png";
import DMF_ICON from "@static/dmf/posters/dmf_icon_2024_08_15.jpg";
import DMF_COVER from "@static/dmf/posters/dmf_cover_2024_08_15.png";
import DMF_BANNER from "@static/dmf/posters/dmf_banner_2024_08_15.png";
import DMF_SCREENSHOT_1 from "@static/dmf/screenshots/dmf_screenshot1_2024_08_15.png";
import DMF_SCREENSHOT_2 from "@static/dmf/screenshots/dmf_screenshot2_2024_08_15.png";
import DMF_SCREENSHOT_3 from "@static/dmf/screenshots/dmf_screenshot3_2024_08_15.png";
import DMF_SCREENSHOT_4 from "@static/dmf/screenshots/dmf_screenshot4_2024_08_15.png";
import DMF_SCREENSHOT_5 from "@static/dmf/screenshots/dmf_screenshot5_2024_08_15.png";
import DMF_SCREENSHOT_6 from "@static/dmf/screenshots/dmf_screenshot6_2024_08_15.png";
import DMF_SCREENSHOT_7 from "@static/dmf/screenshots/dmf_screenshot7_2024_08_15.png";


export const WEATHER_OR_NOT_GALLERY: PublishingGallery = {
    items: [
        {
            link: WON_BANNER,
            type: GameGalleryItemType.VIDEO,
            url: "https://youtube.com/embed/2JaegRGAY7k",
        },
        {
            link: WON_COVER,
            type: GameGalleryItemType.PICTURE,
        },
        {
            link: WON_SCREENSHOT_1,
            type: GameGalleryItemType.PICTURE,
        },
        {
            link: WON_SCREENSHOT_2,
            type: GameGalleryItemType.PICTURE,
        },
        {
            link: WON_SCREENSHOT_3,
            type: GameGalleryItemType.PICTURE,
        },
        {
            link: WON_SCREENSHOT_4,
            type: GameGalleryItemType.PICTURE,
        },
    ],
    selected: undefined,
}

export const WEATHER_OR_NOT_INFO: GameInformation = {
    id: "weather-or-not",
    title: "Weather or Not",
    icon: WON_ICON,
    banner: WON_BANNER,
    cover: WON_COVER,
    stores: [
        {
            distributer: GameDistributer.HOME,
            link: "/games/weather-or-not/play/#game-container",
        },
        {
            distributer: GameDistributer.ITCHIO,
            link: "https://dreamers-graphic-studio.itch.io/weather-or-not",
        },
        {
            distributer: GameDistributer.APP_STORE,
            link: "https://apps.apple.com/gb/app/weather-or-not/id6478780060",
        },
        {
            distributer: GameDistributer.PLAY_STORE,
            link: "https://play.google.com/store/apps/details?id=com.DreamersGraphicStudio.WeatherorNot",
        },
    ],
    gallery: WEATHER_OR_NOT_GALLERY,
    summary: "2D Puzzle Platform",
    description: `Embark on the heart warming tale of a boy's journey to becoming a hero. 
                In the Enchanted Lands of Vaedyre, a land torn between summer and winter, you will encounter tough 
                obstacles, mysterious puzzles and ancient powers. It certainly won't be easy. Vaedyre is full of 
                danger and someone or something is trying to stop you... Weather or Not you survive is your decision.`,
    developer: "Dreamers Graphic Studio",
    publisher: "Dreamers Graphic Studio",
    status: GameStatus.NEW,
    date: "12/04/2024",
    platforms: [
        {
            platform: GamePlatform.BROWSER,
            link: "#",
        },
        {
            platform: GamePlatform.IOS,
            link: "#",
        },
        {
            platform: GamePlatform.ANDROID,
            link: "#",
        },
    ],
    tags: "Web, iOS, Android",
}

export const DEMOLITION_FORCE_GALLERY: PublishingGallery = {
    items: [
        {
            link: DMF_COVER,
            type: GameGalleryItemType.VIDEO,
            url: "https://www.youtube.com/embed/BviY3eXgpU0",
        },
        {
            link: DMF_SCREENSHOT_1,
            type: GameGalleryItemType.PICTURE,
        },
        {
            link: DMF_SCREENSHOT_2,
            type: GameGalleryItemType.PICTURE,
        },
        {
            link: DMF_SCREENSHOT_3,
            type: GameGalleryItemType.PICTURE,
        },
        {
            link: DMF_SCREENSHOT_4,
            type: GameGalleryItemType.PICTURE,
        },
        {
            link: DMF_SCREENSHOT_5,
            type: GameGalleryItemType.PICTURE,
        },
        {
            link: DMF_SCREENSHOT_6,
            type: GameGalleryItemType.PICTURE,
        },
        {
            link: DMF_SCREENSHOT_7,
            type: GameGalleryItemType.PICTURE,
        },
    ],
    selected: undefined,
}

export const DEMOLITION_FORCE_INFO: GameInformation = {
    id: "demolition-force",
    title: "Demolition Force",
    icon: DMF_ICON,
    banner: DMF_BANNER,
    cover: DMF_COVER,
    stores: [
        {
            distributer: GameDistributer.ITCHIO,
            link: "https://dreamers-graphic-studio.itch.io/demolition-force",
        },
        {
            distributer: GameDistributer.WINDOWS_STORE,
            link: "https://apps.microsoft.com/detail/9p6znkr50mdc?amp%3Bgl=GB&hl=en-gb&gl=GB",
        },
        {
            distributer: GameDistributer.PLAY_STORE,
            link: "https://play.google.com/store/apps/details?id=com.DreamersGraphics.DemolitionForce",
        },
    ],
    gallery: DEMOLITION_FORCE_GALLERY,
    summary: "3D Shooting Arcade",
    description: `Demolition Force is an action arcade game which the main objective is to complete 
                missions and score as high as possible by destroying the adversary's tanks and robots. 
                This is the first project of Dreamers Graphic`,
    developer: "Dreamers Graphic Studio",
    publisher: "Dreamers Graphic Studio",
    status: GameStatus.RELEASED,
    date: "01/03/2018",
    platforms: [
        {
            platform: GamePlatform.BROWSER,
            link: "#",
        },
        {
            platform: GamePlatform.WINDOWS,
            link: "#",
        },
        {
            platform: GamePlatform.ANDROID,
            link: "#",
        },
    ],
    tags: "Web, PC, Android",
}
