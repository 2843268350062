import PHOTO_2 from "@static/won/posters/won_poster_2_v006_plain.jpg";

export interface PageBannerPros {
    src: string,
}


export function PageBanner({ src }: PageBannerPros) {

    return (
        <section id="page-banner" className="w-full">
            <div className="relative h-68 md:h-100 flex items-center justify-center overflow-hidden flex-grow bg-slate-500">
                <img src={src} alt="photo..." className='w-full object-cover'></img>
            </div>
        </section >
    )
}
