import { Slide, SlideShow } from "@components/sliders/SlideShow"
import PHOTO_1 from "@static/won/posters/won_poster_1_2024_03_29_001_plain.png";
import PHOTO_2 from "@static/won/posters/won_poster_2_v006_plain.jpg";
import PHOTO_3 from "@static/won/screenshots/won_screenshot_1.png";


export function PageNews() {
    const slide_1: Slide = {
        src: PHOTO_1,
        title: "Weather or Not for Android",
        description: "It is now available on Play Store",
        button: "Get it Now",
        link: "https://play.google.com/store/apps/details?id=com.DreamersGraphicStudio.WeatherorNot",
    }

    const slide_2: Slide = {
        src: PHOTO_2,
        title: "Weather or Not for iOS",
        description: "It is now available on App Store",
        button: "Get it Now",
        link: "https://apps.apple.com/gb/app/weather-or-not/id6478780060",
    }

    const slide_3: Slide = {
        src: PHOTO_1,
        title: "Weather or Not for Browsers",
        description: "It is now available to play on Itch.io",
        button: "Play Now",
        link: "https://dreamers-graphic-studio.itch.io/weather-or-not",
    }

    const slide_4: Slide = {
        src: PHOTO_3,
        title: "Weather or Not Soundtrack",
        description: "Listen to the original game's soundtrack",
        button: "Listen Now",
        link: "https://distrokid.com/hyperfollow/douzaifolu/weather-or-not-original-game-soundtrack/",
    }

    return (
        <section id="page-news" className="w-full">
            <SlideShow slides={[slide_1, slide_2, slide_3, slide_4]} />
        </section >
    )
}