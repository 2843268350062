import { BsFacebook, BsInstagram, BsMailbox, BsTwitterX, BsYoutube } from 'react-icons/bs';
import { IoGameController } from "react-icons/io5";
import { Navbar } from "@components/bars/Navbar"
import { DropdownMenu } from "@components/menus/DropdownMenu"
import { StoreProfile } from "@components/profiles/StoreProfile"
import { SmallProfile } from "@components/profiles/SmallProfile";
import { GameInformation } from '@interfaces/games';
import { useGetGameInfoList } from "@services/hooks/gamePage"
import DGS_LOGO from "@static/logos/dgs/dgs_logo_v2020_08_15_001.png"
import PLAY_STORE_LOGO from "@static/logos/google/google_play_store_2.jpg"
import APP_STORE_LOGO from "@static/logos/apple/app_store_logo_2.png"
import MICROSOFT_STORE_LOGO from "@static/logos/microsoft/microsoft_store_logo.png"
import ITCHIO_LOGO from "@static/logos/itchio/itch.io_logo_2.png"
import KICKSTARTER_LOGO from "@static/logos/kickstarter/kickstarter_logo.png"
import HELIO from "@static/team/helio_da_silva_profile.jpg";
import FRANCESCO from "@static/team/francesco_scopa_profile_2.jpg.jpeg";
import DOGU from "@static/team/dogu_zaifoglu_profile.jpg";


export interface PageHeaderProps {
    games: GameInformation[]
}

export function PageHeader({ games }: PageHeaderProps) {

    return (
        <div className="z-50 w-full fixed">
            <Navbar logo={DGS_LOGO}
                links={[
                    <DropdownMenu url="#" text="Games" key={1}>
                        <>
                            {games.map((game: GameInformation, index: number) => (
                                <StoreProfile key={index} src={game.icon} link={`/games/${game.id}`}
                                    title={game.title} more={game.tags} />
                            ))}
                        </>
                        <a href="/games"
                            className="flex flex-row m-2 p-2 items-center justify-center rounded-md bg-dgs-yellow">
                            <IoGameController className="mr-2" color="red"></IoGameController>
                            <div
                                className="text-lg hover:text-xl font-bold text-dgs-red">
                                All Games
                            </div>
                        </a>
                    </DropdownMenu>,
                    <DropdownMenu url="#" text="Stores" key={2}>
                        <StoreProfile src={ITCHIO_LOGO} link="https://apps.apple.com/gb/developer/helio-silva/id1202100175"
                            title="Itch.io" more="Browser" />
                        <StoreProfile src={MICROSOFT_STORE_LOGO} link="https://apps.microsoft.com/detail/9p6znkr50mdc?hl=en-gb&gl=GB"
                            title="Microsoft Store" more="PC" />
                        <StoreProfile src={APP_STORE_LOGO} link="https://apps.apple.com/gb/developer/helio-silva/id1202100175"
                            title="App Store" more="iOS, Mac" />
                        <StoreProfile src={PLAY_STORE_LOGO} link="https://play.google.com/store/apps/dev?id=9008576379451887444"
                            title="Google Play Store" more="Android" />
                    </DropdownMenu>,
                    <DropdownMenu url="#" text="Team" key={3}>
                        <SmallProfile src={HELIO} title="Hélio Da Silva" description="Lead Programmer" />
                        <SmallProfile src={FRANCESCO} title="Francesco Scopa" description="Game Designer" />
                        <SmallProfile src={DOGU} title="Doğu Zaifoğlu" description="Soundtrack Composer" />
                    </DropdownMenu>,
                    <DropdownMenu url="#" text="Support Us" key={4}>
                        <StoreProfile src={KICKSTARTER_LOGO} link="https://www.kickstarter.com/profile/dgstudio-games"
                            title="Kickstarter" more="Weather or Not" />
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSehnqOIvHPHArJ5jOcwhjqA4HMQZarieTCC4uSUuF9BaceiwA/viewform?usp=sf_link"
                            className="flex flex-row m-2 p-2 items-center justify-center rounded-md bg-dgs-yellow">
                            <IoGameController className="mr-2" color="red"></IoGameController>
                            <div
                                className="text-lg hover:text-xl font-bold text-dgs-red">
                                Become a Tester
                            </div>
                        </a>
                    </DropdownMenu>,
                ]}
                media={[
                    <a href="https://www.facebook.com/DreamersGraphicStudio" key={1}>
                        <BsFacebook className="mr-2" color="white"></BsFacebook>
                    </a>,
                    <a href="https://www.instagram.com/d.g.studio/?hl=en" key={2}>
                        <BsInstagram className="mr-2" color="white"></BsInstagram>
                    </a>,
                    <a href="https://www.youtube.com/@dreamersgraphicstudio/featured" key={3}>
                        <BsYoutube className="mr-2" color="white"></BsYoutube>
                    </a>,
                    <a href="https://twitter.com/dgstudiogames" key={4}>
                        <BsTwitterX className="mr-2" color="white"></BsTwitterX>
                    </a>,
                    <a href="mailto:info@dgstudio.games" key={5}>
                        <BsMailbox className="mr-2" color="white"></BsMailbox>
                    </a>
                ]} />
        </div>
    )
}

export function DynamicPageHeader() {

    const games = useGetGameInfoList()

    return (
        <PageHeader games={games} />
    )
}
