import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GameGalleryItem, PublishingGallery } from "@interfaces/games"
import { WEATHER_OR_NOT_GALLERY } from "@services/api/tempApi"


const initialState: PublishingGallery = {
    items: WEATHER_OR_NOT_GALLERY.items,
    selected: undefined,
}

export const publishingGallerySlice = createSlice({
    name: "publishingGallery",
    initialState,
    reducers: {
        setPublishingGalleryItems: (state, action: PayloadAction<PublishingGallery>) => {
            state.items = action.payload.items
            state.selected = action.payload.selected
        },
        selectPublishingGalleryItem: (state, action: PayloadAction<GameGalleryItem>) => {
            state.selected = action.payload
        },
    },
})

export const {
    setPublishingGalleryItems,
    selectPublishingGalleryItem,
} = publishingGallerySlice.actions
