export enum GameStatus {
    UPCOMING = "UPCOMING",
    DEMO = "DEMO",
    NEW = "NEW",
    RELEASED = "RELEASED",
    UPDATED = "UPDATED",
}


export enum GameDistributer {
    HOME = "HOME",
    ITCHIO = "ITCHIO",
    APP_STORE = "APP_STORE",
    PLAY_STORE = "PLAY_STORE",
    WINDOWS_STORE = "WINDOWS_STORE",
}


export interface GameStores {
    distributer: GameDistributer,
    link: string,
}


export enum GamePlatform {
    BROWSER = "BROWSER",
    IOS = "IOS",
    ANDROID = "ANDROID",
    WINDOWS = "WINDOWS",
}

export interface ImplementedPlatform {
    platform: GamePlatform,
    link: string,
}

export enum GameGalleryItemType {
    PICTURE = "PICTURE",
    VIDEO = "VIDEO",
}

export interface GameGalleryItem {
    link: string
    type: GameGalleryItemType
    url?: string
}

export interface PublishingGallery {
    items: GameGalleryItem[]
    selected: GameGalleryItem | undefined
}

export interface GameInformation {
    id: string
    title: string
    icon: string
    banner: string
    cover: string
    stores: GameStores[]
    gallery: PublishingGallery
    summary: string
    description: string
    developer: string
    publisher: string
    status: GameStatus
    date: string
    platforms: ImplementedPlatform[]
    tags: string
}

export interface GamePages {
    [id: string]: GameInformation
}
