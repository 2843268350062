import { ReactElement } from "react"

export interface LibraryProps {
    title: string,
    children: ReactElement[]
}

export function List({ title, children }: LibraryProps) {

    return (
        <div className="w-full">
            <div className="p-8 whitespace-nowrap text-2xl font-bold text-center
             text-white">{title}</div>
            <div className="w-full flex flex-col items-center justify-center px-2 pb-10 gap-4 overflow-y-auto">
                {children}
            </div>
        </div>
    )
}
