import { GameStatus } from "@interfaces/games"


export function getGameProfileStatus(gameStatus: GameStatus): string {
    switch (gameStatus) {
        case GameStatus.UPCOMING:
            return "Upcoming"
        case GameStatus.DEMO:
            return "Demo"
        case GameStatus.NEW:
            return "NEW"
        case GameStatus.UPDATED:
            return "Updated"
        case GameStatus.RELEASED:
            return ""
        default:
            return ""
    }
}

export function getGamePublishingStatus(gameStatus: GameStatus): string {
    switch (gameStatus) {
        case GameStatus.UPCOMING:
            return "Upcoming"
        case GameStatus.DEMO:
            return "Demo"
        case GameStatus.NEW:
            return "Released"
        case GameStatus.UPDATED:
            return "Released"
        case GameStatus.RELEASED:
            return "Released"
        default:
            return "Released"
    }
}
