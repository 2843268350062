import { useDispatch, useSelector } from "react-redux";
import { GameGalleryItem, PublishingGallery } from "@interfaces/games";
import { selectPublishingGalleryItem } from "@services/slice/publishingGallery";
import { RootState } from "@store";


export function useSelectGalleryItem(item: GameGalleryItem) {
    const dispatch = useDispatch()
    return dispatch(selectPublishingGalleryItem(item))
}

export function useGetPublishingGalleryItem(): PublishingGallery {
    return useSelector((state: RootState) => state.publishingGallery)
}
