export interface SmallProfileProps {
    src: string,
    title: string,
    description: string,
}

export function SmallProfile({ src, title, description }: SmallProfileProps) {

    return (
        <div className='flex flex-row items-center p-2 gap-4'>
            <div className="h-12 w-12 rounded-full border-2 overflow-hidden border-dgs-cyan bg-slate-900">
                <img src={src} alt="photo..." className='h-full w-full object-cover'></img>
            </div>
            <div>
                <div className='text-lg hover:text-xl font-bold text-white'>{title}</div>
                <div className='text-md font-semibold text-gray-500'>{description}</div>
            </div>
        </div>
    )
}
