import { ReactElement } from "react"
import { FaAppStoreIos } from "react-icons/fa";
import { FaStore, FaWindows } from "react-icons/fa6";
import { IoLogoGooglePlaystore } from "react-icons/io5";

import { GameDistributer, GameStores } from "@interfaces/games";


export interface StoreLinkProps {
    icon?: ReactElement
    title: string
    link: string
}

export function StoreLink({ icon, title, link }: StoreLinkProps) {
    return (
        <a href={link} className="bg-dgs-blue-dark-2 hover:bg-dgs-cyan rounded-full flex flex-row items-center justify-end 
        gap-2 px-3 py-1 lg:px-4 lg:py-2">
            {icon}
            <div className="flex-none text-sm md:text font-semibold text-center text-nowrap
             text-dgs-yellow">
                {title}
            </div>
        </a>
    )
}

export interface PublishersLinkProps {
    title: string
    link: string
}

export function PlayHereStoreLink({ title, link }: PublishersLinkProps) {
    return (
        <StoreLink title={title} link={link} />
    )
}

export function AppStoreLink({ title, link }: PublishersLinkProps) {
    return (
        <StoreLink icon={<FaAppStoreIos className="" color="#FFC78B"></FaAppStoreIos>}
            title={title} link={link} />
    )
}

export function ItchioStoreLink({ title, link }: PublishersLinkProps) {
    return (
        <StoreLink icon={<FaStore className="" color="#FFC78B"></FaStore>}
            title={title} link={link} />
    )
}

export function PlayStoreLink({ title, link }: PublishersLinkProps) {
    return (
        <StoreLink icon={<IoLogoGooglePlaystore className="" color="#FFC78B"></IoLogoGooglePlaystore>}
            title={title} link={link} />
    )
}

export function WindowsStoreLink({ title, link }: PublishersLinkProps) {
    return (
        <StoreLink icon={<FaWindows className="" color="#FFC78B"></FaWindows>}
            title={title} link={link} />
    )
}

export interface GetStoreLinkProps {
    store: GameStores
}

export function GetStoreLink({ store }: GetStoreLinkProps) {
    switch (store.distributer) {
        case GameDistributer.HOME:
            return <PlayHereStoreLink title="Play Here" link={store.link} />
        case GameDistributer.APP_STORE:
            return <AppStoreLink title="App Store" link={store.link} />
        case GameDistributer.ITCHIO:
            return <ItchioStoreLink title="Itch.io" link={store.link} />
        case GameDistributer.PLAY_STORE:
            return <PlayStoreLink title="Play Store" link={store.link} />
        case GameDistributer.WINDOWS_STORE:
            return <WindowsStoreLink title="Windows Store" link={store.link} />
        default:
            return <PlayHereStoreLink title="Play Here" link={store.link} />
    }
}
