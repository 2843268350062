import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Home } from '@pages/Home';
import { Games } from "@pages/Games";
import { DemolitionForcePrivacyPolicy } from "@pages/games/demolition_force/DemolitionForcePrivacyPolicy";
import { TowerOfPuzzlesPrivacyPolicy } from "@pages/games/tower_of_puzzles/TowerOfPuzzlesPrivacyPolicy";
import { DynamicGenericGamePage } from "@pages/games/GenericGamePage";
import { WeatherOrNotGame } from "@pages/games/weather_or_not/WeatherOrNotGame";
import { WeatherOrNotPrivacyPolicy } from "@pages/games/weather_or_not/WeatherOrNotPrivacyPolicy";
import { LayoutPage } from "@pages/LayoutPage";


function App() {
  return (
    <div data-testid="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LayoutPage />}>
            <Route index element={<Home />} />
            <Route path="games" element={<Games />} />
            <Route path="games/:id" element={<DynamicGenericGamePage />} />
            <Route path="games/weather-or-not/play" element={<WeatherOrNotGame />} />
            <Route path="games/weather-or-not/privacy-policy" element={<WeatherOrNotPrivacyPolicy />} />
            <Route path="games/tower-of-puzzles/privacy-policy" element={<TowerOfPuzzlesPrivacyPolicy />} />
            <Route path="games/demolition-force/privacy-policy" element={<DemolitionForcePrivacyPolicy />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
