export interface HorizontalProfileProps {
    src: string,
    title: string,
    description: string,
}

export function HorizontalProfile({ src, title, description }: HorizontalProfileProps) {

    return (
        <div className='flex flex-row items-center gap-2'>
            <div className="h-20 w-20 rounded-full border-2 overflow-hidden border-dgs-cyan bg-slate-900">
                <img src={src} alt="photo..." className='h-full w-full object-cover'></img>
            </div>
            <div>
                <div className='w-36 text-md md:text-lg font-extrabold text-left text-white'>{title}</div>
                <div className='w-36 text-xs md:text-sm font-semibold text-left text-dgs-cyan'>{description}</div>
            </div>
        </div>
    )
}
