import { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { GameGalleryItem, GameGalleryItemType } from "@interfaces/games";
import { useGetPublishingGalleryItem } from "@services/hooks/publishingGallery";
import { selectPublishingGalleryItem } from "@services/slice/publishingGallery";


export interface GamePlayGalleryItemProps {
    src: string,
}

export function GamePlayGalleryPhoto({ src }: GamePlayGalleryItemProps) {
    return (
        <img alt="..." src={src} className='w-full object-cover'></img>
    )
}

export function GamePlayGallerySelectedPhoto({ src }: GamePlayGalleryItemProps) {
    return (
        <div className="absolute w-full h-full md:w-120 md:h-99 overflow-clip flex items-center justify-center">
            <img alt="..." src={src} className='w-full object-cover rounded-sm pb-1'></img>
        </div>
    )
}

export function GamePlayGalleryVideo({ src }: GamePlayGalleryItemProps) {
    return (
        <iframe className="aspect-video md:w-120 md:h-99 rounded-sm" src={src} allowFullScreen allow="fullscreen; autoplay"></iframe>
    )
}

export interface GamePlayGalleryProps {
    children?: ReactElement[],
    selected?: ReactElement
}

export function GamePlayGallery({ children, selected }: GamePlayGalleryProps) {
    return (

        <div className="w-full md:w-120 flex flex-col gap-2">
            {selected}
            {/* Game gallery */}
            <div className="w-full">
                {/* Game pictures */}
                <div className="w-full flex flex-row items-center justify-start gap-2 custom-scrollbar" >
                    {children}
                </div>
            </div>

        </div>
    )
}

export interface GamePlayGalleryPhotoButtonProps {
    item: GameGalleryItem
}

export function GamePlayGalleryPhotoButton({ item }: GamePlayGalleryPhotoButtonProps) {

    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(selectPublishingGalleryItem(item))
    }

    return (
        <button onClick={onClick} className="h-16 w-28 flex-none flex justify-center items-center rounded-sm overflow-y-clip
        mb-1 hover:border-2 hover:border-dgs-cyan">
            <GamePlayGalleryPhoto src={item.link} />
        </button>
    )
}

export function GamePlayGalleryDynamic() {
    const { items, selected } = useGetPublishingGalleryItem()

    const getSelectedItem = (selected: GameGalleryItem) => {
        return (selected.type === GameGalleryItemType.VIDEO && selected.url !== undefined) ?
            <GamePlayGalleryVideo src={selected.url} /> :
            <div className="relative pb-aspect-16-9 md:w-auto md:h-auto">
                <GamePlayGallerySelectedPhoto src={selected.link} />
            </div>
    }

    return (
        <GamePlayGallery selected={selected ? getSelectedItem(selected) : getSelectedItem(items[0])}>
            {items.map((item: GameGalleryItem, index: number) => (
                <GamePlayGalleryPhotoButton item={item} key={index} />
            ))}
        </GamePlayGallery>
    )
}
