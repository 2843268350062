import { ReactElement, useState } from "react"
import { BsFacebook, BsInstagram, BsMailbox, BsTwitterX, BsYoutube } from 'react-icons/bs';
import { FaAppStoreIos, FaKickstarterK } from "react-icons/fa";
import { FaStore, FaWindows } from "react-icons/fa6";
import { HiOutlineMenu, HiOutlineUser, HiX, HiUserGroup, HiNewspaper } from 'react-icons/hi';
import { IoGameController, IoLogoGooglePlaystore } from "react-icons/io5";

import DGS_LOGO_1 from "@static/logos/dgs/dgs_logo_v2020_08_15_001.png"
import GAME_LOGO from "@static/won/posters/won_poster_2_v006_plain.jpg"


export function LeftMenu() {
    return (
        <div className="h-full flex-grow gap-2 bg-dgs-blue-dark-1">
            {/* MenuHeader */}
            <div className="h-12 w-full my-2 flex flex-col flex-none items-center justify-center">
                <img src={DGS_LOGO_1} alt="DGS logo" className="h-full object-cover"></img>
            </div>
            {/* Menu */}
            <div className="bg-dgs-blue-dark-2">
                <div className="flex flex-col px-8 py-4 gap-4">
                    <a className="text-lg hover:text-2xl font-bold text-dgs-yellow">Stores</a>
                    <div className="flex flex-row items-center">
                        <IoLogoGooglePlaystore className="mr-2" color="white"></IoLogoGooglePlaystore>
                        <a href="https://play.google.com/store/apps/dev?id=9008576379451887444"
                            className="text-lg hover:text-xl font-bold text-white">
                            Google Play Store
                        </a>
                    </div>
                    <div className="flex flex-row items-center">
                        <FaAppStoreIos className="mr-2" color="white"></FaAppStoreIos>
                        <a href="https://apps.apple.com/gb/developer/helio-silva/id1202100175"
                            className="text-lg hover:text-xl font-bold text-white">
                            App Store
                        </a>
                    </div>
                    <div className="flex flex-row items-center">
                        <FaStore className="mr-2" color="white"></FaStore>
                        <a href="https://dreamers-graphic-studio.itch.io"
                            className="text-lg hover:text-xl font-bold text-white">
                            Itch.io
                        </a>
                    </div>
                    <div className="flex flex-row items-center">
                        <FaWindows className="mr-2" color="white"></FaWindows>
                        <a href="https://apps.microsoft.com/detail/9p6znkr50mdc?hl=en-gb&gl=GB"
                            className="text-lg hover:text-2xl font-bold text-white">
                            Windows Store
                        </a>
                    </div>
                    <a href="/games/weather-or-not" className="my-2 flex flex-row items-center">
                        <div className="h-12 w-12 mr-4 flex flex-col flex-none items-center justify-center rounded-md overflow-hidden">
                            <img src={GAME_LOGO} alt="Game logo" className="h-full object-cover"></img>
                        </div>
                        <div>
                            <div
                                className="text-lg hover:text-xl font-bold text-white">
                                Weather or Not
                            </div>
                            <div
                                className="text-md font-semibold text-gray-500">
                                2D Platformer
                            </div>
                        </div>
                    </a>
                    <a href="/#page-games"
                        className="flex flex-row p-2 items-center justify-center rounded-md bg-dgs-yellow">
                        <IoGameController className="mr-2" color="red"></IoGameController>
                        <div
                            className="text-lg hover:text-xl font-bold text-dgs-red">
                            All Games
                        </div>
                    </a>
                </div>
            </div>
            {/* MenuFooter */}
            <div className="flex-grow">
                <div className="flex flex-col px-8 py-4 gap-2">
                    <a className="text-lg hover:text-2xl font-bold text-dgs-yellow">Menu</a>
                    <div className="flex flex-row items-center">
                        <HiNewspaper className="mr-2" color="white"></HiNewspaper>
                        <a href="/#page-news"
                            className="text-lg hover:text-2xl font-bold text-white">
                            Latest News
                        </a>
                    </div>
                    <div className="flex flex-row items-center">
                        <HiUserGroup className="mr-2" color="white"></HiUserGroup>
                        <a href="/#team-list"
                            className="text-lg hover:text-2xl font-bold text-white">
                            Our Team
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function RightMenu() {
    return (
        <div className="h-full flex-grow gap-2 bg-dgs-blue-dark-1">
            {/* MenuHeader */}
            <div className="h-12 w-full my-2 flex flex-col flex-none items-center justify-center">
                <img src={DGS_LOGO_1} alt="DGS logo" className="h-full object-cover"></img>
            </div>
            {/* Menu */}
            <div className="bg-dgs-blue-dark-2">
                <div className="flex flex-col px-8 py-4 gap-4">
                    <a className="text-lg hover:text-2xl font-bold text-dgs-yellow">Support us</a>
                    <div className="flex flex-row items-center">
                        <FaKickstarterK className="mr-2" color="white"></FaKickstarterK>
                        <a href="https://www.kickstarter.com/profile/dgstudio-games"
                            className="text-lg hover:text-xl font-bold text-white">
                            Kickstarter
                        </a>
                    </div>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSehnqOIvHPHArJ5jOcwhjqA4HMQZarieTCC4uSUuF9BaceiwA/viewform?usp=sf_link"
                        className="flex flex-row p-2 items-center justify-center rounded-md bg-dgs-yellow">
                        <IoGameController className="mr-2" color="red"></IoGameController>
                        <div
                            className="text-lg hover:text-1xl font-bold text-dgs-red">
                            Become a Tester
                        </div>
                    </a>
                </div>
            </div>
            {/* MenuFooter */}
            <div className="flex-grow">
                <div className="flex flex-col px-8 py-4 gap-2">
                    <div className="text-lg hover:text-2xl font-bold text-dgs-yellow">Follow us</div>
                    <div className="flex flex-row items-center">
                        <BsFacebook className="mr-2" color="white"></BsFacebook>
                        <a href="https://www.facebook.com/DreamersGraphicStudio"
                            className="text-lg hover:text-2xl font-bold text-white">
                            Facebook
                        </a>
                    </div>
                    <div className="flex flex-row items-center">
                        <BsInstagram className="mr-2" color="white"></BsInstagram>
                        <a href="https://www.instagram.com/d.g.studio/?hl=en"
                            className="text-lg hover:text-2xl font-bold text-white">
                            Instagram
                        </a>
                    </div>
                    <div className="flex flex-row items-center">
                        <BsYoutube className="mr-2" color="white"></BsYoutube>
                        <a href="https://www.youtube.com/@dreamersgraphicstudio/featured"
                            className="text-lg hover:text-2xl font-bold text-white">
                            YouTube
                        </a>
                    </div>
                    <div className="flex flex-row items-center">
                        <BsTwitterX className="mr-2" color="white"></BsTwitterX>
                        <a href="https://twitter.com/dgstudiogames"
                            className="text-lg hover:text-2xl font-bold text-white">
                            Twitter X
                        </a>
                    </div>
                    <div className="flex flex-row items-center">
                        <BsMailbox className="mr-2" color="white"></BsMailbox>
                        <a href="mailto:info@dgstudio.games"
                            className="text-lg hover:text-2xl font-bold text-white">
                            Email
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export interface NavbarProps {
    logo?: string,
    links?: ReactElement[],
    media?: ReactElement[],
}

export function Navbar({ logo, links, media }: NavbarProps) {

    const [showLeftMenu, setShowLeftMenu] = useState("hidden")
    const [showRightMenu, setShowRightMenu] = useState("hidden")
    const leftMenuState = showLeftMenu
    const rightMenuState = showRightMenu

    const setShowLeftMenuTrue = () => {
        setShowLeftMenu("block animate-slide-in-left")
    }
    const setShowLeftMenuFalse = () => {
        setShowLeftMenu("animate-slide-out-left")
    }
    const setShowRightMenuTrue = () => {
        setShowRightMenu("block animate-slide-in-right")
    }
    const setShowRightMenuFalse = () => {
        setShowRightMenu("animate-slide-out-right")
    }

    return (
        <div className="relative">
            <div className="h-16 w-full bg-dgs-blue-dark-2 opacity-100 absolute z-0 ">
                <div className="w-full h-full grid grid-cols-3 md:flex md:flex-row ">
                    <div className="flex pl-4 items-center md:hidden">
                        <button onClick={setShowLeftMenuTrue} className="h-6 w-6">
                            <HiOutlineMenu className="h-full w-full" color="#00FFFF"></HiOutlineMenu>
                        </button>
                    </div>
                    <a href="/" className="flex items-center md:hidden">
                        <img src={logo} alt="Dreamers Graphics Studio Logo"
                            className="object-contain md:px-6 md:py-2">
                        </img>
                    </a>
                    <div className="absolute hidden md:block mx-6 h-16 p-2">
                        <a href="/" className="h-full flex items-center justify-center">
                            <img src={logo} alt="Dreamers Graphics Studio Logo"
                                className="object-contain h-full">
                            </img>
                        </a>
                    </div>
                    <div className="hidden md:w-full md:flex md:flex-row md:items-center md:gap-2 md:ml-40">
                        {links}
                    </div>
                    <div className="flex pr-4 items-center justify-end md:hidden">
                        <button onClick={setShowRightMenuTrue} className="h-6 w-6">
                            <HiOutlineUser className="h-full w-full" color="#00FFFF"></HiOutlineUser>
                        </button>
                    </div>
                    <div className="hidden w-full md:flex md:flex-row items-center justify-end px-4 gap-2 md:px-6 md:gap-4">
                        {media}
                    </div>
                </div>
            </div>
            <div style={{ animationFillMode: 'forwards' }} className={`${leftMenuState} md:hidden`}>
                <div className={`absolute z-10 h-screen w-full`}>
                    <div className="h-full w-full flex flex-row">
                        <LeftMenu></LeftMenu>
                        <div className="h-full w-8"></div>
                    </div>
                </div>
                <div className="absolute z-20 flex pl-4 pt-5 items-center">
                    <button onClick={setShowLeftMenuFalse} className="h-6 w-6">
                        <HiX className="h-full w-full" color="white"></HiX>
                    </button>
                </div>
            </div>
            <div style={{ animationFillMode: 'forwards' }} className={`${rightMenuState} md:hidden`}>
                <div className={`absolute z-10 h-screen w-full`}>
                    <div className="h-full w-full flex flex-row">
                        <div className="h-full w-8"></div>
                        <RightMenu></RightMenu>
                    </div>
                </div>
                <div className="absolute right-0 z-20 flex pr-4 pt-5 items-center">
                    <button onClick={setShowRightMenuFalse} className="h-6 w-6">
                        <HiX className="h-full w-full" color="white"></HiX>
                    </button>
                </div>
            </div>
        </div>
    )
}
