export interface VerticalProfileProps {
    src: string,
    title: string,
    description: string,
}

export function VerticalProfile({ src, title, description }: VerticalProfileProps) {

    return (
        <div className='flex flex-col items-center'>
            <div className="h-24 w-24 md:h-36 md:w-36 rounded-full border-2 overflow-hidden border-dgs-cyan bg-slate-900">
                <img src={src} alt="photo..." className='h-full w-full object-cover'></img>
            </div>
            <div className='w-36 md:w-44 text-md md:text-lg font-extrabold text-center text-white'>{title}</div>
            <div className='w-36 md:w-44 text-xs md:text-sm font-semibold text-center text-dgs-cyan'>{description}</div>
        </div>
    )
}
