export interface TextLinkProps {
    url: string,
    text: string,
}


export function TextLink({ url, text }: TextLinkProps) {

    return (
        <div>
            <a href={url} className="p-2 text-lg font-bold text-dgs-yellow">{text}</a>
        </div>
    )
}
