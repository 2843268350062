import { DynamicGameLibrary } from "@components/sections/GameLibrary"
import { PageFooter } from "@components/sections/PageFooter"
import { DynamicPageHeader } from "@components/sections/PageHeader"
import { PageNews } from "@components/sections/PageNews"
import { TeamList } from "@components/sections/TeamList"


export function Home() {

    return (
        <div className="min-h-screen bg-gradient-to-br from-slate-800 to-black">
            <DynamicPageHeader />
            <div className="">
                <PageNews />
                <DynamicGameLibrary />
                <TeamList />
                <PageFooter />
            </div>
        </div>
    )
}
