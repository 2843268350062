import { createSlice, PayloadAction } from "@reduxjs/toolkit"


export interface PlatformFilters {
    browser: boolean,
    windows: boolean,
    ios: boolean,
    android: boolean,
    home: boolean,
}

export interface GameLibrary {
    filters: PlatformFilters
}

const initialState: GameLibrary = {
    filters: {
        browser: true,
        windows: false,
        ios: false,
        android: false,
        home: false,
    }
}

export const gameLibrarySlice = createSlice({
    name: "gameLibrary",
    initialState,
    reducers: {
        setBrowserFilter: (state, action: PayloadAction<boolean>) => {
            state.filters.browser = action.payload
        },
        setWindowsFilter: (state, action: PayloadAction<boolean>) => {
            state.filters.windows = action.payload
        },
        setIosFilter: (state, action: PayloadAction<boolean>) => {
            state.filters.ios = action.payload
        },
        setAndroidFilter: (state, action: PayloadAction<boolean>) => {
            state.filters.android = action.payload
        },
        setHomeFilter: (state, action: PayloadAction<boolean>) => {
            state.filters.home = action.payload
        },
    },
})

export const {
    setAndroidFilter,
    setBrowserFilter,
    setHomeFilter,
    setIosFilter,
    setWindowsFilter,
} = gameLibrarySlice.actions
