import { GameStatus } from "@interfaces/games"
import { getGameProfileStatus } from "@utils/game";

export interface GameProfileProps {
    src: string,
    title: string,
    description: string,
    link: string,
    status?: string
}

export function GameProfile({ src, title, description, link, status }: GameProfileProps) {

    return (
        <a href={link}>
            <div className='flex flex-col items-center'>
                <div className="relative h-48 w-36 md:h-80 md:w-60 rounded-md overflow-hidden
                bg-slate-900 shadow-lg shadow-black
                hover:shadow-xl hover:shadow-dgs-cyan
                 ">
                    <img src={src} alt="photo..." className='h-full w-full object-cover'></img>
                    {status ? <div className="absolute top-0 right-0 m-2 px-2 lg:py-1 lg:px-3 z-10 rounded-md
                    text-center text-xs lg:text-sm font-bold bg-dgs-blue-dark-2 text-dgs-cyan
                    border-2 border-dgs-cyan">
                        {status}</div> : <></>}
                </div>
                <div className='mt-2 w-36 md:w-48 text-md md:text-xl font-extrabold text-center text-white'>{title}</div>
                <div className='w-36 md:w-48 text-xs md:text-sm font-semibold text-center text-dgs-cyan'>{description}</div>
            </div>
        </a>
    )
}

export interface DynamicGameProfileProps {
    src: string,
    title: string,
    description: string,
    link: string,
    status: GameStatus
}

export function DynamicGameProfile({ src, title, description, link, status }: DynamicGameProfileProps) {

    return (
        <GameProfile link={link}
            src={src} title={title} description={description} status={getGameProfileStatus(status)} />
    )
}
