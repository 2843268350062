import { configureStore } from '@reduxjs/toolkit';
import { telemetryApi } from "@services/api/telemetry";
import { publishingGallerySlice } from '@services/slice/publishingGallery';
import { gameLibrarySlice } from '@services/slice/gameLibrary';
import { gamePageSlice } from '@services/slice/gamePage';


export const store = configureStore({
    reducer: {
        [telemetryApi.reducerPath]: telemetryApi.reducer,
        [publishingGallerySlice.name]: publishingGallerySlice.reducer,
        [gamePageSlice.name]: gamePageSlice.reducer,
        [gameLibrarySlice.name]: gameLibrarySlice.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(telemetryApi.middleware),
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
