import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const telemetryApi = createApi({
    reducerPath: 'telemetryApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:5000' }),
    endpoints: (builder) => ({
        getGameByName: builder.query({
            query: () => `/mazes`,
        }),
    }),
})


export const { useGetGameByNameQuery } = telemetryApi
