export interface StoreProfileProps {
    src: string,
    link: string,
    title: string,
    more: string,
}

export function StoreProfile({ src, link, title, more }: StoreProfileProps) {

    return (
        <a href={link} className="p-2 flex flex-row items-center rounded-md hover:bg-dgs-blue-dark-1">
            <div className="h-12 w-12 mr-4 flex flex-col flex-none items-center justify-center rounded-md overflow-hidden">
                <img src={src} alt="store logo" className="h-full object-cover"></img>
            </div>
            <div>
                <div
                    className="text-lg hover:text-xl font-bold text-white">
                    {title}
                </div>
                <div
                    className="text-md font-semibold text-gray-500">
                    {more}
                </div>
            </div>
        </a>
    )
}