import { GameContainer } from "@components/containers/GameContainer";
import { PageFooter } from "@components/sections/PageFooter"
import { DynamicPageHeader } from "@components/sections/PageHeader"
import { PageBanner } from "@components/sections/PageBanner"
import PHOTO_2 from "@static/won/posters/won_poster_2_v006_plain.jpg";


export function WeatherOrNotGame() {

    return (
        <div className="min-h-screen bg-slate-900">
            <DynamicPageHeader />
            <div className="">
                <div className="relative h-80 md:h-100 flex items-center justify-center flex-grow">
                    <div className="absolute z-0">
                        <PageBanner src={PHOTO_2} />
                    </div>
                    <div className="absolute z-10 w-full">
                        <div className="bg-black h-16 opacity-0"></div>
                        <div className="bg-black h-36 opacity-25"></div>
                    </div>
                    <div className="absolute z-20">
                        <div className="text-white text-3xl md:text-5xl font-bold text-center text-opacity-0">W</div>
                        <div className="text-white text-2xl md:text-5xl font-bold text-center">Play Area</div>
                        <div className="text-white text-3xl md:text-7xl font-bold text-center">Weather or Not</div>
                    </div>
                </div>
                <div id="game-container" className="py-4 px-4 md:px-24 xl:px-48">
                    <GameContainer
                        loaderUrl="/unity/won/WON_WebGL_2024_06_17_001.loader.js"
                        dataUrl="/unity/won/WON_WebGL_2024_06_17_001.data.unityweb"
                        frameworkUrl="/unity/won/WON_WebGL_2024_06_17_001.framework.js.unityweb"
                        codeUrl="/unity/won/WON_WebGL_2024_06_17_001.wasm.unityweb" />
                </div>
                <PageFooter />
            </div>
        </div>
    )
}
