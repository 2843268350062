import { Library } from "@components/containers/Library";
import { DynamicGameProfile } from "@components/profiles/GameProfile";
import { GameInformation, GamePlatform } from "@interfaces/games";
import { useGetLibraryFilters } from "@services/hooks/gameLibrary";
import { useGetGameInfoListByFilters } from "@services/hooks/gamePage"


export interface GameLibraryProps {
    games: GameInformation[]
}

export function GameLibrary({ games }: GameLibraryProps) {
    return (
        <section id="page-games" className="w-full">
            <Library title="OUR GAMES"
                categories={[GamePlatform.BROWSER, GamePlatform.WINDOWS, GamePlatform.IOS, GamePlatform.ANDROID]}>
                {games.map((game: GameInformation, index: number) => (
                    <DynamicGameProfile key={index} link={`/games/${game.id}`}
                        src={game.cover} title={game.title} description={game.summary} status={game.status} />
                ))}
            </Library>
        </section >
    )
}

export function DynamicGameLibrary() {

    const filters = useGetLibraryFilters()
    const games = useGetGameInfoListByFilters(filters)

    return (
        <GameLibrary games={games} />
    )
}
