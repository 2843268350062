
'use client';

import { Footer } from 'flowbite-react';
import { BsDiscord, BsFacebook, BsInstagram, BsMailbox, BsTwitterX, BsYoutube } from 'react-icons/bs';

export function PageFooter() {
    return (
        <Footer bgDark>
            <div className="w-full">
                <div className="w-full grid grid-cols-2 gap-8 px-6 py-8 md:flex md:flex-row md:justify-center md:gap-16">
                    <div>
                        <Footer.Title title="Studio" />
                        <Footer.LinkGroup col>
                            <Footer.Link href="/#page-news">News</Footer.Link>
                            <Footer.Link href="/#team-list">Team</Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                    <div>
                        <Footer.Title title="Platforms" />
                        <Footer.LinkGroup col>
                            <Footer.Link href="https://dreamers-graphic-studio.itch.io">Itch.io</Footer.Link>
                            <Footer.Link href="https://play.google.com/store/apps/dev?id=9008576379451887444">Google Play Store</Footer.Link>
                            <Footer.Link href="https://apps.apple.com/gb/developer/helio-silva/id1202100175">App Store</Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                    <div>
                        <Footer.Title title="Top Games" />
                        <Footer.LinkGroup col>
                            <Footer.Link href="https://dreamers-graphic-studio.itch.io/weather-or-not">Weather or Not</Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                    <div>
                        <Footer.Title title="Soundtracks" />
                        <Footer.LinkGroup col>
                            <Footer.Link href="https://distrokid.com/hyperfollow/douzaifolu/weather-or-not-original-game-soundtrack/">Weather or Not</Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                </div>
                <div className="w-full bg-gray-700 px-4 py-6 sm:flex sm:items-center sm:justify-between">
                    <div className='flex justify-center'>
                        <Footer.Copyright href="#" by="Dreamers Graphic Studio™" year={2023} />
                    </div>
                    <div className="mt-4 flex space-x-6 sm:mt-0 justify-center">
                        <Footer.Icon href="https://www.facebook.com/DreamersGraphicStudio" icon={BsFacebook} />
                        <Footer.Icon href="https://www.instagram.com/d.g.studio/?hl=en" icon={BsInstagram} />
                        <Footer.Icon href="https://www.youtube.com/@dreamersgraphicstudio/featured" icon={BsYoutube} />
                        <Footer.Icon href="https://twitter.com/dgstudiogames" icon={BsTwitterX} />
                        <Footer.Icon href="mailto:info@dgstudio.games" icon={BsMailbox} />
                    </div>
                </div>
            </div>
        </Footer>
    );
}
